<template>
  <div class="h100">
    <common-layout>
      <template #header>
        <el-form inline>
          <el-form-item label="框架名称">
            <el-input placeholder="请输入" v-model="form.name" clearable></el-input>
          </el-form-item>
          <el-form-item label="框架编码">
            <el-input placeholder="请输入" v-model="form.code" clearable></el-input>
          </el-form-item>
          <el-form-item label="标签">
            <el-select v-model="form.label" clearable>
              <el-option v-for="(item, index) in labelList" :key="index" :value="item.label" :label="item.label"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="query">查询</el-button>
            <el-button type="primary" plain @click="handleAdd(false)">新增</el-button>
            <el-button type="primary" plain @click="lableDrawer = true">标签管理</el-button>
          </el-form-item>
        </el-form>

        <el-tabs v-model="activeState" type="card" @tab-click="activeChange">
          <el-tab-pane label="全部" name="1"></el-tab-pane>
          <el-tab-pane label="微服务" name="2"></el-tab-pane>
          <el-tab-pane label="非微服务" name="3"></el-tab-pane>
          <el-tab-pane label="容器部署" name="4"></el-tab-pane>
          <el-tab-pane label="非容器部署" name="5"></el-tab-pane>
        </el-tabs>
      </template>
      <template #default>
        <el-table ref="table" :data="tableData" v-loading="tableLoading" border stripe height="100%">
          <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
          <el-table-column prop="name" label="框架名称" align="center" min-width="100" />
          <el-table-column prop="code" label="框架编码" align="center" min-width="100" />
          <el-table-column prop="backFrameworkVersionId" label="后端框架-版本" align="center" min-width="100">
            <template slot-scope="scope">
              {{scope.row.backFrameworkVersionCode}}-{{scope.row.backFrameworkVersionVersion}}
            </template>
          </el-table-column>
          <el-table-column prop="frontFrameworkId" label="前端框架-版本" align="center" min-width="100">
            <template slot-scope="scope">
              {{scope.row.frontFrameworkCode}}-{{scope.row.frontFrameworkVersion}}
            </template>
          </el-table-column>
          <el-table-column prop="microServiceDesc" label="微服务" align="center" />
          <el-table-column prop="dockerDeployDesc" label="容器部署" align="center" />
          <el-table-column prop="label" label="标签" align="center" min-width="150" />
          <el-table-column prop="createdBy" label="创建账号" align="center" />
          <el-table-column prop="createTime" label="创建时间" align="center" min-width="150" />
          <el-table-column prop="updateBy" label="修改账号" align="center" />
          <el-table-column prop="updateTime" label="修改时间" align="center" min-width="150" />
          <el-table-column label="操作" align="center" width="220" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="handleAdd(true, scope.row)"> 编辑 </el-button>
              <el-button type="text" @click="handleApp('basics',scope.row)"> 基础应用 </el-button>
              <el-button type="text" @click="handleApp('workflow',scope.row)"> 工作流应用 </el-button>
              <el-button type="text" @click="handleDelete(scope.row)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template #footer>
        <el-pagination class="mt10" :page-size="page.pageSize" :current-page="page.pageNum" :total="page.total" @size-change="handleSizeChange" @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next, jumper"></el-pagination>
      </template>
    </common-layout>
    <el-drawer title="标签管理" :visible.sync="lableDrawer" size="80%" direction="rtl" @close="getData()">
      <lableMgt></lableMgt>
    </el-drawer>
    <add-modal :detail="rowData" :show="addDrawer" :isEdit="isEdit" @getData="getData" @close="modalClose"></add-modal>
    <app-modal :detail="appRowData" :show="appDrawer" :appType="appType" @getData="getData" @close="modalClose"></app-modal>
  </div>
</template>
<script>
import addModal from "./addModal";
import appModal from "./appModal";
import lableMgt from "../../common/labelMgt.vue";
import Api from "@/api/devops/framework";
import commonApi from "@/api/devops/common";
export default {
  components: { addModal, lableMgt, appModal },
  data() {
    return {
      form: {},
      tableData: [],
      tableLoading: false,
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      lableDrawer: false,
      addDrawer: false,
      appType: null,
      activeState: "1",
      rowData: {},
      isEdit: true,
      labelList: [],
      appDrawer: false,
      appRowData: {},
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    query() {
      this.getData();
    },

    async getData(val) {
      let params = {
        ...this.form,
        ...val,
        current: this.page.current,
        size: this.page.size,
      };
      let res = await Api.getSystem(params);
      this.tableData = res.data.records;
      this.page.total = res.data.total;
      this.getLabel();
    },
    async getLabel() {
      let res = await commonApi.getLabel();
      if (res.data) {
        this.labelList = res.data;
      }
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.page.current = 1;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page.current = val;
      this.getData();
    },
    handleAdd(isEdit, data) {
      if (isEdit) {
        this.rowData = _.cloneDeep(data);
      } else {
        this.rowData = {};
      }
      this.addDrawer = true;
      this.isEdit = isEdit;
    },
    async handleApp(type, rowData) {
      this.appType = type;
      const fn = type == "basics" ? Api.getApplication : Api.getWorkflow;
      let { code, data } = await fn(rowData.id);
      this.appRowData = {
        ...data,
        systemName: rowData.name,
        systemArtifactCode: rowData.code,
        systemArtifactId: rowData.id,
      };
      this.appDrawer = true;
    },
    modalClose() {
      this.addDrawer = false;
      this.appDrawer = false;
    },
    handleDelete(data) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await Api.deSystem(data.id);
        this.$msg.success("操作成功");
        this.getData();
      });
    },
    activeChange() {
      let screen = {};
      switch (this.activeState) {
        case "1":
          break;
        case "2":
          screen = { microService: 1 };
          break;
        case "3":
          screen = { microService: 0 };
          break;
        case "4":
          screen = { dockerDeploy: 1 };
          break;
        case "5":
          screen = { dockerDeploy: 0 };
          break;
      }
      this.getData(screen);
    },
  },
};
</script>
<style>
.el-form--label-left .el-form-item__label {
  text-align: right;
}
</style>
