<template>
  <div>
    <el-button type="primary" @click="addRely">{{ titleData }}</el-button>
    <el-table ref="table" :data="list" border highlight-current-row class="mt10" :height="height">
      <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
      <el-table-column prop="artifactId" label="依赖名称" align="center" />
      <el-table-column prop="groupId" label="依赖公司" align="center" />
      <el-table-column prop="branch" label="分支" align="center" v-if="type == 'basal'" />
      <el-table-column prop="version" label="版本" align="center" v-else />
      <el-table-column prop="scope" label="依赖作用域" align="center" />
      <el-table-column prop="type" label="依赖类型" align="center" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="delTable(scope.$index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="选择依赖" width="1000px" v-if="relyShow" :visible="relyShow" @close="relyShow = false" :append-to-body="true" :close-on-click-modal="false">
      <el-form inline>
        <el-form-item label="Name">
          <el-input placeholder="请输入" v-model="query.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="Group">
          <el-input placeholder="请输入" v-model="query.groupId" clearable></el-input>
        </el-form-item>
        <el-form-item label="Artifact">
          <el-input placeholder="请输入" v-model="query.artifactId" clearable></el-input>
        </el-form-item>
        <el-form-item label="版本">
          <el-input placeholder="请输入" v-model="query.version" clearable></el-input>
        </el-form-item>
        <el-form-item label="标签">
          <el-select v-model="query.labelId" clearable>
            <el-option v-for="(item, index) in labelList" :key="index" :value="item.id" :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleQuery(1)">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="relyData" ref="relyData" row-key="dependencyId" v-loading="tableLoading" border highlight-current-row>
        <el-table-column type="selection" reserve-selection width="55" />
        <el-table-column prop="name" label="Name" align="center" />
        <el-table-column prop="groupId" label="Group" align="center" />
        <el-table-column prop="artifactId" label="Artifact" align="center" />
        <el-table-column prop="version" label="version" align="center" />
        <el-table-column prop="labels" label="标签" align="center">
          <template slot-scope="scope">
            <div class="flex-cc" v-for="(item, index) in scope.row.labels" :key="index">
              <el-tooltip effect="dark" :content="item.label" placement="top-start">
                <div class="label-item" :style="{ background: item.color, border: item.color ? '' : '1px solid #ccc' }">
                  {{ item.label }}
                </div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="branch" label="分支" align="center" v-if="type == 'basal'" />
        <el-table-column prop="scope" label="依赖作用域" align="center" />
        <el-table-column prop="type" label="依赖类型" align="center" />
      </el-table>
      <el-pagination class="mt10" :page-size="page.size" :current-page="page.current" :total="page.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper"></el-pagination>

      <div slot="footer">
        <el-button @click="relyShow = false">取消</el-button>
        <el-button type="primary" @click="getSelection">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Api from '@/api/devops/relyMgt'
import commonApi from '@/api/devops/common'
export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    formData: { default: () => {}, type: Object },
    titleData: { type: String, default: '新增依赖' },
    versionList: {
      default: () => [],
      type: Array,
    },
    height: {
      type: String,
      default: '500px',
    },
  },

  watch: {
    versionList: {
      handler(val) {
        this.list = val
      },
    },
  },
  data() {
    return {
      list: [],
      tableLoading: false,
      form: [],
      labelList: [],
      tableData: [{}],
      relyData: [],
      relyShow: false,

      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      query: {
        name: '',
        label: '',
      },
    }
  },
  created() {
    this.list = this.versionList
  },
  mounted() {},
  methods: {
    addRely() {
      this.relyShow = true
      this.getData(1)
    },
    delTable(index) {
      this.list.splice(index, 1)
    },

    async getData(current) {
      if(current) {
        this.$set(this.page, 'current', current)
      }
      let params = {
        ...this.page,
        ...this.query
      }
      let handleType = 'getOtherRely'
      if (this.type == 'basal') {
        handleType = 'getRely'
      }
      let res = await Api[handleType](params)
      this.relyData = res.data.records
      this.page.total = res.data.total
      this.getLabel()
    },
    handleSizeChange(val) {
      this.page.size = val
      this.page.current = 1
      this.getData()
    },
    handleCurrentChange(val) {
      this.page.current = val
      this.getData()
    },
    getSelection(val) {
      // let selection = this.$refs.relyData.selection.concat(this.list)
      // let newSelection = selection.filter((item, index) => {
      //   let temArr = [] //将对象里的某个属性，放入这个临时数组
      //   selection.forEach((item2) => {
      //     temArr.push(item2.dependencyId) //这里放进临时数组的是每个对象里的id
      //   })
      //   return temArr.indexOf(item.dependencyId) === index // 然后使用indexOf（）方法当作过滤的条件
      // })
      let {selection} = this.$refs.relyData
      let newSelection = [...this.list]
      selection.forEach(item=>{
        if(this.list.every(i=>i.artifactId!=item.artifactId)) {
          newSelection.push(item)
        }
      })
      newSelection.forEach((item) => {
        if (item.id && !item.dependencyId) {
          item.dependencyId = item.id
          delete item.id
        }
      })
      console.log({selection,newSelection})
      this.$nextTick(() => {
        this.list = newSelection
        this.$emit('getRelyData', this.list)
      })
      this.relyShow = false
    },
    async getLabel() {
      let res = await commonApi.getLabel()
      if (res.data) {
        this.labelList = res.data
      }
    },
    handleQuery(current) {
      this.getData(current)
    },
  },
}
</script>
<style lang="scss" scoped>
.label-item {
  display: inline-block;
  height: 30px;
  padding: 0 10px;
  line-height: 28px;
  font-size: 12px;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
  margin: 2px 0;
}
</style>
