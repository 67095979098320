<template>
  <el-dialog :title="isEdit ? '编辑' : '新增'" size="80%" :visible="show" @close="close" :close-on-click-modal="false">
    <el-form ref="form" :model="form" label-width="120px" :rules="rules">
      <el-form-item label="开发语言：" prop="language">
        <el-radio v-model="form.language" label="Java">Java</el-radio>
      </el-form-item>
      <el-form-item label="编译版本：" prop="version">
        <el-radio v-model="form.version" label="8.0">8.0</el-radio>
      </el-form-item>
      <el-form-item label="项目类型：">

        <el-radio-group v-model="form.project">
          <el-radio label="maven">maven</el-radio>
          <el-radio label="gradle">gradle</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否微服务：">
        <el-radio-group v-model="form.microService">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否容器部署：">
        <el-radio-group v-model="form.dockerDeploy">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="标签：" prop="labelId">
        <el-select v-model="form.labelId" clearable class="inputWidth mr20" @change="labelChange">
          <el-option v-for="(item) in labelList" :key="item.id" :value="item.id" :label="item.label"></el-option>
        </el-select>
        <el-button type="primary" @click="addLabel()">新增</el-button>
      </el-form-item>
      <el-form-item label="框架名称：" prop="name">
        <el-input v-model="form.name" class="inputWidth"></el-input>
      </el-form-item>
      <el-form-item label="框架编码：" prop="code">
        <el-input v-model="form.code" class="inputWidth"></el-input>
      </el-form-item>
      <el-form-item label="版本：" prop="version">
        <el-input v-model="form.version" class="inputWidth"></el-input>
      </el-form-item>
      <label class="flex">
        <el-form-item label="后端框架：" prop="backFrameworkId">
          <el-select v-model="form.backFrameworkId" placeholder="请选择" @change="backChange">
            <el-option v-for="item in endList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="框架版本：">
          <el-select v-model="form.backFrameworkVersionId" placeholder="请选择" @change="backVChange">
            <el-option v-for="item in backVersion" :key="item.id" :label="item.backFrameworkVersion" :value="item.backFrameworkVersionId">
            </el-option>
          </el-select>
        </el-form-item>
      </label>
      <label class="flex">
        <el-form-item label="前端框架：" prop="frontFrameworkId">
          <el-select v-model="form.frontFrameworkId" placeholder="请选择" @change="frontChange">
            <el-option v-for="item in frontList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="框架版本：">
          <el-input v-model="form.frontFrameworkVersion" class="inputWidth" disabled></el-input>
        </el-form-item>
      </label>

      <el-form-item label="系统描述：">
        <!-- <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="form.description" class="inputWidth" /> -->

        <wang-editor :value="form.description" @change="(data) => (form.description = data)" class="inputWidth" />
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </div>

    <el-drawer title="标签管理" :visible.sync="lableDrawer" :append-to-body="true" size="85%" direction="rtl" @close="getLabel()">
      <lableMgt></lableMgt>
    </el-drawer>
  </el-dialog>
</template>
<script>
import Api from "@/api/devops/framework";
import commonApi from "@/api/devops/common";
import lableMgt from "../../common/labelMgt.vue";
import WangEditor from "@/components/wangEditor";
export default {
  components: { lableMgt, WangEditor },
  props: {
    show: {
      default: true,
      type: Boolean,
    },
    detail: {
      type: Object,
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    show(val) {
      val ? (this.getLabel(), this.getFrameAll()) : false;
    },
    detail(val) {
      this.isEdit = !_.isEmpty(val);
      this.form = this.isEdit
        ? val
        : {
            language: "Java",
            microService: 0,
            dockerDeploy: 0,
            version: "8.0",
            project: "maven",
          };
      if (this.form.id) {
        this.getVersion(this.form.backFrameworkId);
      }
    },
  },
  data() {
    return {
      form: {
        language: "1",
        edition: "1",
        type: "1",
        isMicro: "2",
        isDeploy: "2",
      },
      backOptions: [
        {
          value: "Java",
          label: "Java",
        },
      ],
      frontOptions: [
        {
          value: "vue",
          label: "vue",
        },
        {
          value: "react",
          label: "react",
        },
      ],
      frontFrameworkId: "",
      backValue: "",
      editionValue1: "",
      frontFrameworkVersion: "",
      editionOptions: [
        {
          value: "1",
          label: "8.0",
        },
      ],
      lableDrawer: false,
      labelList: [],
      endList: [],
      frontList: [],
      backVersion: [],
      rules: {
        name: [{ required: true, message: "请输入框架名称", trigger: "blur" }],
        labelId: [{ required: true, message: "请选择标签", trigger: "blur" }],
        code: [{ required: true, message: "请输入框架编码", trigger: "blur" }],
        version: [{ required: true, message: "请输入版本", trigger: "blur" }],
        backFrameworkId: [
          { required: true, message: "请选择后端框架", trigger: "blur" },
        ],
        frontFrameworkId: [
          { required: true, message: "请选择前端框架", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.fetch();
        } else {
          return false;
        }
      });
    },
    async fetch() {
      let params = this.form;
      const fn = this.form.id ? Api.updateSystem : Api.addSystem;
      let res = await fn(params);
      this.$msg.success("操作成功");
      this.close();
      this.$emit("getData");
    },

    close() {
      this.$emit("close");
    },
    async getLabel() {
      let res = await commonApi.getLabel();
      if (res.data) {
        this.labelList = res.data;
      }
    },
    async getFrameAll() {
      let endRes = await Api.getEndAll();
      let frontRes = await Api.getFrontAll();
      if (endRes.data) {
        this.endList = endRes.data;
      }
      if (frontRes.data) {
        this.frontList = frontRes.data;
      }
    },
    addLabel() {
      this.lableDrawer = true;
    },
    async backChange(val) {
      this.endList.forEach((item) => {
        if (item.id === val) {
          this.form.backFrameworkVersionCode = item.name;
        }
      });
      delete this.form.backFrameworkVersionId;
      this.getVersion(val);
    },
    async getVersion(val) {
      let { code, data } = await Api.getVersion(val);
      this.backVersion = data;
    },
    backVChange(val) {
      this.backVersion.forEach((item) => {
        if (item.backFrameworkVersionId === val) {
          this.form.backFrameworkVersionVersion = item.backFrameworkVersion;
        }
      });
    },
    frontChange(val) {
      this.frontList.forEach((item) => {
        if (item.id === val) {
          this.form.frontFrameworkCode = item.name;
          this.form.frontFrameworkVersion = item.version;
        }
      });
    },

    labelChange(value) {
      this.labelList.forEach((item) => {
        if (item.id === value) {
          this.form.label = item.label;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.inputWidth {
  width: 80%;
}
</style>