<template>
  <el-dialog :title="appType=='basics'?'基础应用':'工作流应用'" width="60%" :visible="show" @close="close" :close-on-click-modal="false">
    <el-form ref="form" :model="form" label-width="180px" :rules="rules">
      <el-form-item label="系统框架：">
        <el-input v-model="form.systemName" class="inputWidth" disabled></el-input>
      </el-form-item>
      <el-form-item label="应用名称：" prop="name">
        <el-input v-model="form.name" class="inputWidth"></el-input>
      </el-form-item>
      <el-form-item label="应用编码：" prop="code">
        <el-input v-model="form.code" class="inputWidth"></el-input>
      </el-form-item>
      <el-form-item label="租户账号：" prop="tenantAccount">
        <el-input v-model="form.tenantAccount" class="inputWidth"></el-input>
      </el-form-item>
      <el-form-item label="租户标识：" prop="tenantSecret">
        <el-input v-model="form.tenantSecret" class="inputWidth"></el-input>
      </el-form-item>
      <el-form-item label="应用描述：">
        <!-- <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="form.description" class="inputWidth"/> -->
        <wang-editor :value="form.description" @change="(data) => (form.description = data)" class="inputWidth" />
      </el-form-item>

      <common-layout class="flex-1 flex-column h100">
        <template #header>
          <el-form inline>
            <el-form-item>
              <el-button type="primary" @click="addTable">{{appType=='basics'?'选择模块':'选择工作流模块'}}</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template #default>
          <el-table :data="modulesList" border highlight-current-row height="200px">
            <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
            <el-table-column label="模块名称" prop="name" width="100" align="center" class="tableInput" />
            <el-table-column label="模块编码" prop="code" width="200" align="center" />
            <el-table-column label="httpUrlToRepo" prop="httpUrlToRepo" align="center" />
            <el-table-column label="版本" prop="version" align="center" width="200">
              <template slot-scope="scope">
                <el-form-item label-width="0" style="margin-bottom: 0;">
                  <el-select v-model="scope.row.version" clearable @click.native="showVersion(scope.row)">
                    <el-option v-for="(item, index) in versionList" :key="index+1" :label="item" :value="item" />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="80px">
              <template slot-scope="scope">
                <!-- <el-button type="text" @click="showVersion(scope.row)">版本</el-button> -->
                <el-button type="text" @click="delTable(scope.$index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </common-layout>
      <common-layout class="flex-1 flex-column h100">

        <div class="mt10">
          <select-table v-if="show" titleData="新增基础依赖" height="200px" :versionList="dependenciesList" @getRelyData="getRelyData" type="basal" />
        </div>
      </common-layout>

    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </div>
    <el-dialog title="选择模块" width="60%" v-if="moduleShow" :visible="moduleShow" @close="moduleShow=false" :append-to-body="true" :close-on-click-modal="false">

      <el-table :data="moduleDataAll" ref="moduleDataAll" row-key='projectRepoId' :reserve-selection="true" border highlight-current-row class="h100">
        <el-table-column type="selection" reserve-selection width="55" />
        <el-table-column label="模块名称" prop="name" width="200" align="center" class="tableInput" />
        <el-table-column label="模块编码" prop="code" width="200" align="center" />
        <el-table-column label="httpUrlToRepo" prop="httpUrlToRepo" align="center" />
      </el-table>
      <el-pagination class="mt10" :page-size="page.size" :current-page="page.current" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"></el-pagination>

      <div slot="footer">
        <el-button @click="moduleShow =false">取消</el-button>
        <el-button type="primary" @click="getSelection">确定</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>
<script>
import Api from "@/api/devops/framework";
import commonApi from "@/api/devops/common";
import selectTable from "../../common/selectTable.vue";
import WangEditor from "@/components/wangEditor";
export default {
  components: { selectTable, WangEditor },
  props: {
    show: {
      default: true,
      type: Boolean,
    },
    detail: {
      type: Object,
      default: () => ({}),
    },
    appType: {
      type: String,
      default: "",
    },
  },
  watch: {
    show(val) {
      val ? this.getLabel() : false;
    },
    detail(val) {
      this.form = val;
      if (this.appType == "basics") {
        this.dependenciesList = val.architectureDependencies ?? [];
        this.modulesList = val.architectureModules ?? [];
      } else {
        this.dependenciesList = val.workflowDependencies ?? [];
        this.modulesList = val.workflowModules ?? [];
      }
      this.form.description = val?.description ?? "";
    },
  },
  data() {
    return {
      versionList: [],
      labelList: [],
      moduleDataAll: [],
      form: {},
      tableData: [{}],
      moduleShow: false,
      dependenciesList: [],
      modulesList: [],
      page: {
        current: 1,
        size: 10,
      },
      total: null,
      rules: {
        name: [{ required: true, message: "请输入应用名称", trigger: "blur" }],
        code: [{ required: true, message: "请输入应用编码", trigger: "blur" }],
        tenantAccount: [
          { required: true, message: "请输入租户账号", trigger: "blur" },
        ],
        tenantSecret: [
          { required: true, message: "请输入租户标识", trigger: "blur" },
        ],
        // "architectureModules.version": [
        //   { required: true, message: "请选择模块版本", trigger: "blur" },
        // ],
      },
    };
  },
  mounted() {},
  methods: {
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.fetch();
        } else {
          return false;
        }
      });
    },

    async fetch() {
      let fn = new Function();
      let params = {};
      if (this.appType == "basics") {
        fn = this.form.id ? Api.updateApplication : Api.addApplication;
        params = {
          ...this.form,
          architectureDependencies: this.dependenciesList,
          architectureModules: this.modulesList,
        };
      } else {
        fn = this.form.id ? Api.updateWorkflow : Api.addWorkflow;
        params = {
          ...this.form,
          workflowDependencies: this.dependenciesList,
          workflowModules: this.modulesList,
        };
      }
      let { code } = await fn(params);
      this.$msg.success("操作成功");
      this.close();
      this.$emit("getData");
    },
    getRelyData(data) {
      this.dependenciesList = data;
    },
    close() {
      this.dependenciesList = [];
      this.$emit("close");
    },

    async getLabel() {
      let res = await commonApi.getLabel();
      if (res.data) {
        this.labelList = res.data;
      }
    },
    addTable() {
      if (this.form.tenantAccount) {
        this.moduleShow = true;
        this.getModuleData();
      } else {
        this.$message.error("请输入租户账号");
      }
    },
    delTable(index) {
      this.modulesList.splice(index, 1);
    },
    getSelection() {
      let selection = this.$refs.moduleDataAll.selection.concat(
        this.modulesList
      );
      let newSelection = selection.filter((item, index) => {
        let temArr = []; //将对象里的某个属性，放入这个临时数组
        item.version = item.version ?? "";
        selection.forEach((item2) => {
          temArr.push(item2.projectRepoId); //这里放进临时数组的是每个对象里的id
        });
        return temArr.indexOf(item.projectRepoId) === index; // 然后使用indexOf（）方法当作过滤的条件
      });
      this.$nextTick(() => {
        this.modulesList = newSelection;
      });
      this.moduleShow = false;
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.page.current = 1;
      this.getModuleData();
    },
    handleCurrentChange(val) {
      this.page.current = val;
      this.getModuleData();
    },
    async getModuleData() {
      let { systemArtifactId, backFrameworkId } = this.detail;
      let params = {
        tenantCode: this.form.tenantAccount,
        ...this.page,
      };
      let { data } = await Api.getModule(params);
      this.moduleDataAll = data?.records || [];
      this.total = data?.total || 0;
    },
    async showVersion(row) {
      let params = `${row.code}/${this.form.tenantAccount}`;
      let { data, code } = await Api.getModuleVersion(params);
      this.versionList = data;
    },
  },
};
</script>
<style lang="scss" scoped>
.inputWidth {
  width: 60%;
}
</style>